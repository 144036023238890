import mutationError from 'utils/getMutationError'
import { ApolloClient } from '@apollo/client'
import { OperationVariables } from '@apollo/client/core/types'
import { MutationOptions } from '@apollo/client/core/watchQueryOptions'
// import { BlockUiActions } from 'components/BlockUi'
// import { ToastArgs } from 'components/Toast'

type Args = {
  // openToast: (props: ToastArgs) => void
  client: ApolloClient<object>
  successMessage?: string
  showError?: boolean
  confirmation?: Promise<boolean>
  raiseError?: boolean
  // blockUi?: BlockUiActions | null
}

export default async function executeMutation<
  T,
  TVariables = OperationVariables
>(
  options: MutationOptions<T, TVariables>,
  {
    // openToast,
    client,
    successMessage,
    showError = true,
    confirmation,
    // blockUi = null,
    raiseError = !showError
  }: Args
): Promise<T | null> {
  if (confirmation) {
    if (!(await confirmation)) return null
  }
  let error = null
  try {
    // if (blockUi) {
    //   blockUi.startBlockUi()
    // }
    const response = await client.mutate<T, TVariables>(options)
    const { data } = response
    const errorMessage = mutationError(response)
    if (errorMessage) {
      // if (showError) {
      //   openToast({ title: 'Error', message: errorMessage, variant: 'error' })
      // }
      error = new Error(errorMessage)
    } else {
      if (successMessage) {
        // openToast({
        //   title: 'Success',
        //   message: successMessage,
        //   variant: 'success'
        // })
      }
      return data!
    }
  } catch (e) {
    if (showError) {
      // openToast({ title: 'Error', message: e.message, variant: 'error' })
    }
    error = e
  } finally {
    // if (blockUi) {
    //   blockUi.stopBlockUi()
    // }
  }
  if (raiseError) {
    throw error
  } else {
    return null
  }
}
