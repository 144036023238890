/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from '../../../global-graphql-types';

import { DocumentNode } from 'graphql';
import { CurrentOperatorFragment } from '../../../queries/generated/CurrentOperatorFragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/extendApolloHooks';
const defaultOptions =  {}
export type OperatorSignUpVariables = Types.Exact<{
  idToken: Types.Scalars['String'];
}>;


export type OperatorSignUp = { __typename: 'Mutation', operatorSignUp?: Types.Maybe<{ __typename: 'OperatorSignupPayload', operator?: Types.Maybe<{ __typename: 'Operator', id: number, name: string, email: string, company: { __typename: 'Company', id: number, name: string } }>, errors?: Types.Maybe<Array<{ __typename: 'MutationError', message?: Types.Maybe<string> }>> }> };


export const OperatorSignUpDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"OperatorSignUp"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"idToken"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"operatorSignUp"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"idToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"idToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"operator"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrentOperatorFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"errors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}},...CurrentOperatorFragment.definitions]} as unknown as DocumentNode;
export type OperatorSignUpMutationFn = Apollo.MutationFunction<OperatorSignUp, OperatorSignUpVariables>;

/**
 * __useOperatorSignUp__
 *
 * To run a mutation, you first call `useOperatorSignUp` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOperatorSignUp` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [operatorSignUp, { data, loading, error }] = useOperatorSignUp({
 *   variables: {
 *      idToken: // value for 'idToken'
 *   },
 * });
 */
export function useOperatorSignUp(baseOptions?: ApolloReactHooks.MutationHookOptions<OperatorSignUp, OperatorSignUpVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<OperatorSignUp, OperatorSignUpVariables>(OperatorSignUpDocument, options);
      }
export type OperatorSignUpHookResult = ReturnType<typeof useOperatorSignUp>;
export type OperatorSignUpMutationResult = Apollo.MutationResult<OperatorSignUp>;
export type OperatorSignUpMutationOptions = Apollo.BaseMutationOptions<OperatorSignUp, OperatorSignUpVariables>;