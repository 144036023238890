/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from '../../../global-graphql-types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/extendApolloHooks';
const defaultOptions =  {}
export type OperatorLogoutVariables = Types.Exact<{ [key: string]: never; }>;


export type OperatorLogout = { __typename: 'Mutation', operatorLogout?: Types.Maybe<{ __typename: 'OperatorLogoutPayload', success?: Types.Maybe<boolean>, errors?: Types.Maybe<Array<{ __typename: 'MutationError', message?: Types.Maybe<string> }>> }> };


export const OperatorLogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"OperatorLogout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"operatorLogout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]} as unknown as DocumentNode;
export type OperatorLogoutMutationFn = Apollo.MutationFunction<OperatorLogout, OperatorLogoutVariables>;

/**
 * __useOperatorLogout__
 *
 * To run a mutation, you first call `useOperatorLogout` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOperatorLogout` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [operatorLogout, { data, loading, error }] = useOperatorLogout({
 *   variables: {
 *   },
 * });
 */
export function useOperatorLogout(baseOptions?: ApolloReactHooks.MutationHookOptions<OperatorLogout, OperatorLogoutVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<OperatorLogout, OperatorLogoutVariables>(OperatorLogoutDocument, options);
      }
export type OperatorLogoutHookResult = ReturnType<typeof useOperatorLogout>;
export type OperatorLogoutMutationResult = Apollo.MutationResult<OperatorLogout>;
export type OperatorLogoutMutationOptions = Apollo.BaseMutationOptions<OperatorLogout, OperatorLogoutVariables>;