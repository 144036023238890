import {
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
  AuthProvider,
  User
} from 'firebase/auth'
import rollbar from 'utils/rollbar'

export default function useSigninPopup() {
  return async ({
    provider
  }: {
    provider: 'google' | 'github'
  }): Promise<{ user: User; accessToken: string }> => {
    const auth = (await import('lib/auth/getFirebaseAuth')).default()
    let providerInstance: AuthProvider
    switch (provider) {
      case 'google': {
        providerInstance = new GoogleAuthProvider()
        break
      }
      case 'github': {
        providerInstance = new GithubAuthProvider()
        break
      }
      default:
        throw new Error('Unknown auth provider')
    }
    try {
      const result = await signInWithPopup(auth, providerInstance)
      // const result = await new Promise<UserCredential>((resolve, reject) => {
      //   ;(signInWithPopup(auth, providerInstance) as any)
      //     .then(resolve)
      //     .catch(async (error: any) => {
      //       // https://firebase.google.com/docs/auth/web/google-signin#handling-account-exists-with-different-credential-errors
      //       if (error.code === 'auth/account-exists-with-different-credential') {
      //         const email = error.customData.email
      //         // // Step 2.
      //         // // User's email already exists.
      //         // // The pending Google credential.
      //         // const pendingCred = error.credential
      //         // console.log(pendingCred)
      //         // // The provider account's email address.
      //         // const { email } = error
      //         // // Get sign-in methods for this email.
      //         // console.log(email)
      //         // const methods = await fetchSignInMethodsForEmail(auth, email)
      //         // const methods = await linkWithPopup(auth, email)
      //         throw error
      //       }
      //     })
      // })
      const accessToken = await result.user.getIdToken()
      const { user } = result
      return {
        user,
        accessToken
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        const newError = new Error(
          'You have already signed up via a different method'
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(newError as any).code = error.code
        throw newError
      }
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // // The email of the user's account used.
      // const email = error.email;
      // // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      rollbar.warn(error)
      throw error
    }
  }
}
