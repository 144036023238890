import React, { useContext, useEffect } from 'react'
import { setReportingUser } from 'utils/rollbar'
import useSigninPopup from 'lib/auth/useSigninPopup'
import { User } from 'firebase/auth'
import { useApolloClient } from '@apollo/client'
import executeMutation from 'utils/executeMutation'
import useOperator from 'queries/useOperator'
import { useRouter } from 'next/router'
import {
  CurrentOperatorDocument,
  CurrentOperator
} from 'queries/generated/CurrentOperator'

import {
  OperatorLogin,
  OperatorLoginVariables,
  OperatorLoginDocument
} from './generated/OperatorLogin'
import {
  OperatorSignUp,
  OperatorSignUpVariables,
  OperatorSignUpDocument
} from './generated/OperatorSignUp'
import {
  OperatorLogout,
  OperatorLogoutVariables,
  OperatorLogoutDocument
} from './generated/OperatorLogout'

export type AuthMethod = 'google' | 'github'

export type AuthContextValues = {
  operator: CurrentOperator['currentOperator'] | null
  startLogin: (
    provider: AuthMethod
  ) => Promise<CurrentOperator['currentOperator'] | null>
  startSignUp: (
    provider: AuthMethod
  ) => Promise<CurrentOperator['currentOperator'] | null>
  firebaseSignIn: ({
    provider
  }: {
    provider: AuthMethod
  }) => Promise<{ user: User; accessToken: string } | null>
  logout: () => Promise<void>
  // updateOperator: (operator: Operator | null) => void
}

export const AuthContext = React.createContext<AuthContextValues>({
  operator: null,
  startLogin: () => Promise.resolve(null),
  startSignUp: () => Promise.resolve(null),
  firebaseSignIn: () => Promise.resolve(null),
  logout: () => Promise.resolve(undefined)
  // updateOperator: () => undefined
})

export const useAuth = (): AuthContextValues => {
  return useContext(AuthContext)
}

type Props = {
  children: React.ReactElement | React.ReactElement[]
  isStatic: boolean
  onUserLoad?: (operator: CurrentOperator['currentOperator'] | null) => void
}

export const AuthProvider = ({ children, isStatic, onUserLoad }: Props) => {
  const client = useApolloClient()
  const router = useRouter()
  let operator: CurrentOperator['currentOperator'] | null
  if (isStatic && typeof window === 'undefined') {
    operator = null
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks, prefer-destructuring
    const useResult = useOperator()
    operator = useResult.operator
  }

  useEffect(() => {
    setReportingUser(operator)
  }, [operator])

  const firebaseSignIn = useSigninPopup()

  const startLogin = async (provider: AuthMethod) => {
    const authResult = await firebaseSignIn({ provider })
    const response = await executeMutation<
      OperatorLogin,
      OperatorLoginVariables
    >(
      {
        mutation: OperatorLoginDocument,
        variables: {
          idToken: authResult.accessToken
        },
        update(cache, { data }) {
          if (!data) return
          if (data.operatorLogin!.errors) {
            return
          }
          const { operatorLogin } = data!
          cache.writeQuery({
            query: CurrentOperatorDocument,
            data: {
              currentOperator: operatorLogin?.operator
            }
          })
        }
      },
      { client, raiseError: true }
    )
    const newOperator = response!.operatorLogin!.operator!

    if (onUserLoad && newOperator) onUserLoad(newOperator)

    return newOperator
  }

  const startSignUp = async (provider: AuthMethod) => {
    const authResult = await firebaseSignIn({ provider })
    const response = await executeMutation<
      OperatorSignUp,
      OperatorSignUpVariables
    >(
      {
        mutation: OperatorSignUpDocument,
        variables: {
          idToken: authResult.accessToken
        },
        update(cache, { data }) {
          if (!data) return
          if (data.operatorSignUp!.errors) {
            return
          }
          const { operatorSignUp } = data!
          cache.modify({
            fields: {
              currentOperator() {
                const newRef = cache.writeFragment({
                  data: operatorSignUp?.operator,
                  fragment: CurrentOperatorDocument
                })
                return newRef
              }
            }
          })
        }
      },
      { client, raiseError: true }
    )
    const newOperator = response!.operatorSignUp!.operator!

    if (onUserLoad) onUserLoad(newOperator)

    return newOperator
  }

  const logout = async () => {
    ;(await import('lib/auth/getFirebaseAuth')).default().signOut()

    await executeMutation<OperatorLogout, OperatorLogoutVariables>(
      {
        mutation: OperatorLogoutDocument,
        update: (proxy) => {
          proxy.writeQuery({
            query: CurrentOperatorDocument,
            data: { currentOperator: null }
          })
        }
      },
      { client, raiseError: true }
    )

    await router.push('/')
  }

  return (
    <AuthContext.Provider
      value={{ operator, startSignUp, startLogin, logout, firebaseSignIn }}
    >
      {children}
    </AuthContext.Provider>
  )
}
