import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { setReportingUser } from 'utils/rollbar'
import {
  CurrentOperator,
  CurrentOperatorDocument
} from 'queries/generated/CurrentOperator'

type Result = {
  loading: boolean
  operator: CurrentOperator['currentOperator'] | null
  refetch: () => void
}

type Args = {
  skip: boolean
}

export default function useOperator({ skip }: Args = { skip: false }): Result {
  const { loading, data, refetch } = useQuery<CurrentOperator>(
    CurrentOperatorDocument,
    {
      fetchPolicy: 'cache-first',
      skip
    }
  )
  const operator = data?.currentOperator || null

  useEffect(() => {
    if (typeof window === 'undefined') {
      setReportingUser(operator)
    }
  }, [operator])

  return {
    loading,
    operator,
    refetch
  }
}
