/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from '../../global-graphql-types';

import { DocumentNode } from 'graphql';
import { CurrentOperatorFragment } from './CurrentOperatorFragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/extendApolloHooks';
const defaultOptions =  {}
export type CurrentOperatorVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentOperator = { __typename: 'Query', currentOperator?: Types.Maybe<{ __typename: 'Operator', id: number, name: string, email: string, company: { __typename: 'Company', id: number, name: string } }> };


export const CurrentOperatorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentOperator"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentOperator"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrentOperatorFragment"}}]}}]}},...CurrentOperatorFragment.definitions]} as unknown as DocumentNode;

/**
 * __useCurrentOperator__
 *
 * To run a query within a React component, call `useCurrentOperator` and pass it any options that fit your needs.
 * When your component renders, `useCurrentOperator` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentOperator({
 *   variables: {
 *   },
 * });
 */
export function useCurrentOperator(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentOperator, CurrentOperatorVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CurrentOperator, CurrentOperatorVariables>(CurrentOperatorDocument, options);
      }
export function useCurrentOperatorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentOperator, CurrentOperatorVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CurrentOperator, CurrentOperatorVariables>(CurrentOperatorDocument, options);
        }
export type CurrentOperatorHookResult = ReturnType<typeof useCurrentOperator>;
export type CurrentOperatorLazyQueryHookResult = ReturnType<typeof useCurrentOperatorLazyQuery>;
export type CurrentOperatorQueryResult = Apollo.QueryResult<CurrentOperator, CurrentOperatorVariables>;