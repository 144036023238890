import * as Apollo from '@apollo/client'
import { ConfirmArgs } from 'components/ConfirmDialog'
import getMutationError from 'utils/getMutationError'
import { ApolloError } from '@apollo/client'
import useToast from 'hooks/useToast'
import { useBlockUi } from 'components/BlockUi'
import useConfirm from 'hooks/useConfirm'
import { FetchResult } from '@apollo/client/link/core'
import {
  MutationFunctionOptions,
  MutationResult
} from '@apollo/client/react/types/types'
import uniq from 'lodash/uniq'

export * from '@apollo/client'

interface Notifications {
  onSuccess?:
    | {
        title?: string
        message: string
        timeout?: number
      }
    | string
  onError?:
    | {
        title?: string
        message?: string
        timeout?: number
      }
    | string
    | boolean
}

export type QueryHookOptions<TData, TVariables> = Apollo.QueryHookOptions<
  TData,
  TVariables
> & {
  notifications?: Notifications
}

export function useQuery<
  TData = unknown,
  TVariables = Apollo.OperationVariables
>(
  query: Apollo.DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): Apollo.QueryResult<TData, TVariables> {
  const addToast = useToast()

  return Apollo.useQuery<TData, TVariables>(query, {
    ...options,
    onCompleted: (data) => {
      if (options?.notifications?.onSuccess) {
        addToast({
          variant: 'success',
          ...successMessageOptions(options)
        })
      }
      if (options?.onCompleted) options.onCompleted(data)
    },
    onError: (e) => {
      if (options?.notifications?.onError) {
        addToast({
          ...errorMessageOptions(options, getSystemError(e)),
          variant: 'error'
        })
      }
      if (options?.onError) options.onError(e)
    }
  })
}

export type MutationHookOptions<TData, TVariables> = Apollo.MutationHookOptions<
  TData,
  TVariables
> & {
  notifications?: Notifications
  confirm?: ConfirmArgs | string
  blockUi?: boolean
}

export function useMutation<
  TData = unknown,
  TVariables = Apollo.OperationVariables
>(
  mutation: Apollo.DocumentNode,
  options: MutationHookOptions<TData, TVariables> = {}
): [
  (
    mutationOptions?: MutationFunctionOptions<TData, TVariables>
  ) => Promise<FetchResult<TData> | null>,
  MutationResult<TData>
] {
  const addToast = useToast()
  const confirmation = useConfirm()
  const blockUi = useBlockUi()
  const [onMutate, mutationResult] = Apollo.useMutation<TData, TVariables>(
    mutation,
    {
      ...options,

      onCompleted: (data) => {
        const error = getMutationError({ data })
        if (error) {
          if (options?.notifications?.onError) {
            addToast({
              ...errorMessageOptions(options, error),
              variant: 'error'
            })
          }
          if (options.onError) {
            options.onError(
              new ApolloError({ graphQLErrors: [], errorMessage: error })
            )
          }
          return
        }
        if (options?.notifications?.onSuccess) {
          addToast({
            variant: 'success',
            ...successMessageOptions(options)
          })
        }
        if (options?.onCompleted) options.onCompleted(data)
      },
      onError: (e) => {
        if (options?.notifications?.onError) {
          addToast({
            ...errorMessageOptions(options, getSystemError(e)),
            variant: 'error'
          })
        }
        if (options?.onError) options.onError(e)
      }
    }
  )

  const onMutateWrapper: (
    mutationOptions?: MutationFunctionOptions<TData, TVariables>
  ) => Promise<FetchResult<TData> | null> = async (
    mutationOptions: MutationHookOptions<TData, TVariables> = {}
  ) => {
    const actualConfirm = mutationOptions.confirm ?? options.confirm
    if (actualConfirm) {
      if (!(await confirmation(makeConfirmOptions(actualConfirm)))) {
        return null
      }
    }
    const actualBlockUi = mutationOptions.blockUi ?? options.blockUi
    try {
      if (actualBlockUi) {
        blockUi.startBlockUi()
      }
      return await onMutate(mutationOptions)
    } finally {
      if (actualBlockUi) {
        blockUi.stopBlockUi()
      }
    }
  }
  return [onMutateWrapper, mutationResult]
}

function successMessageOptions(options: { notifications?: Notifications }) {
  let title: string
  let message: string
  let timeout: number
  if (typeof options?.notifications?.onSuccess === 'string') {
    title = 'Success'
    message = options?.notifications?.onSuccess
    timeout = 10000
  } else {
    title = options?.notifications?.onSuccess?.title || 'Success'
    message = options?.notifications?.onSuccess?.message!
    timeout = options?.notifications?.onSuccess?.timeout ?? 10000
  }
  return { title, message, timeout }
}

function errorMessageOptions(
  options: { notifications?: Notifications },
  errorMessage: string
) {
  let title: string
  let message: string
  let timeout: number | undefined
  if (typeof options?.notifications?.onError === 'string') {
    title = 'Error'
    message = options?.notifications?.onError
    timeout = undefined
  } else if (typeof options?.notifications?.onError === 'boolean') {
    title = 'Error'
    message = errorMessage
    timeout = undefined
  } else {
    title = options?.notifications?.onError?.title || 'Error'
    message = options?.notifications?.onError?.message || errorMessage
    timeout = options?.notifications?.onError?.timeout
  }
  return { title, message, timeout }
}

function makeConfirmOptions(input: ConfirmArgs | string): ConfirmArgs {
  if (typeof input === 'string') {
    return {
      title: 'Are you sure?',
      message: input
    }
  }
  return input
}

function getSystemError(err: ApolloError): string {
  const networkError = err?.networkError?.message
  const gqlErrors = err?.graphQLErrors?.map((i) => i.message) || []
  const messages = uniq(
    [err.message, networkError, ...gqlErrors].filter((e) => e && e.length > 0)
  )
  return messages.join(', \n').trim()
}
